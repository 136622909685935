
import { createGlobalStyle } from 'styled-components'
import px2vw from "../utils/px2vw";


// Reset some CSS properties 
// Define our HTML root with font sizes that we will use
// px2vw function converts pixels to viewport width


export const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  :root {
      font-size: ${px2vw(24)};

      @media (min-width: 768px) {
        font-size: ${px2vw(18)};
      }

      @media (min-width: 1024px) {
        font-size: ${px2vw(16)};
      }
    }
`;

export default Global;