export const PHOTOES =
[{
  src: "https://live.staticflickr.com/65535/50852170161_2462ebb69c_o.jpg",
  width: 5,
  height: 7
},
{
  src:"https://live.staticflickr.com/65535/50852265212_b16c7ff86e_o.jpg",
  width: 7,
  height: 5  
},
{
    src: "https://live.staticflickr.com/65535/50852170166_e486692b95_o.jpg",
    width: 7,
    height: 5  
},
{
  src: "https://live.staticflickr.com/65535/50852170146_2a7d17f284_o.jpg",
  width: 7,
  height: 5  
},
{
  src:"https://live.staticflickr.com/65535/50851449443_462b267725_o.jpg",
  width: 5,
  height: 7
},

{
  src: "https://live.staticflickr.com/65535/50851449373_c01a3c4d3c_o.jpg",
  width: 5,
  height: 7  
},
{
  src:"https://live.staticflickr.com/65535/50851449358_1d15045c2c_o.jpg",
  width: 5,
  height: 7
},
{
  src:"https://live.staticflickr.com/65535/50852265127_18ab5952e0_o.jpg",
  width: 5,
  height: 7 
},
{
  src:"https://live.staticflickr.com/65535/50852265097_3ac5009393_o.jpg",
  width: 5,
  height: 7 
},
{
  src:"https://live.staticflickr.com/65535/50852169991_d2bc393046_o.jpg",
  width: 5,
  height: 7 
},
{
  src:"https://live.staticflickr.com/65535/50852265092_66816a6c62_o.jpg",
  width: 7,
  height: 5
},
{
  src:"https://live.staticflickr.com/65535/50852169931_7ce6536cba_o.jpg",
  width: 5,
  height: 7 
},
{
  src:"https://live.staticflickr.com/65535/50851449203_cf0c537578_o.jpg",
  width: 7,
  height: 5
},
{
  src:"https://live.staticflickr.com/65535/50851449188_196cc0f1d5_o.jpg",
  width: 5,
  height: 7 
},
{
  src:"https://live.staticflickr.com/65535/50852169871_1a14c81386_o.jpg",
  width: 5,
  height: 7 
},
{
  src:  "https://live.staticflickr.com/65535/50852825877_fac94ae914_b.jpg",
  width: 5,
  height: 7 
},

{
  src: "https://live.staticflickr.com/65535/50852733141_86b864ce2f_b.jpg",
  width: 7,
  height: 5 
},
{
  src:  "https://live.staticflickr.com/65535/50852825937_e76e0ce350_b.jpg",
  width: 7,
  height: 5
},
{
    src: "https://live.staticflickr.com/65535/50852733101_9c0aecdcd0_b.jpg",
    width: 5,
    height: 7
  },
  {
    src:  "https://live.staticflickr.com/65535/50852170061_ac55d4536a_b.jpg" ,
    width: 7,
    height: 5
  },
  {
    src: "https://live.staticflickr.com/65535/50852170001_c3e3bc8d09_b.jpg" ,
    width: 5,
    height: 7,
  },
  {
    src: "https://live.staticflickr.com/65535/50852265017_1191642827_b.jpg" ,
    width: 7,
    height: 5
  },
  {
    src: "https://live.staticflickr.com/65535/50852264902_b7d944eeca_b.jpg" ,
    width: 5,
    height: 7
  },
  {
    src: "https://live.staticflickr.com/65535/50851449118_bede986261_b.jpg" ,
    width: 5,
    height: 7
  },
  {
    src:  "https://live.staticflickr.com/65535/50852265222_b481a74b6a_b.jpg" ,
    width: 7,
    height: 5
  },
  {
    src: "https://live.staticflickr.com/65535/50851449108_9209a66f71_b.jpg" ,
    width: 7,
    height: 5
  },
  {
    src: "https://live.staticflickr.com/65535/50852065668_a57eef0cd9_b.jpg" ,
    width: 7,
    height: 5
  },
]





